<template>
<div>
  <b-container fluid>
    <b-row class="py-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Data elements lineage</h1>
        <p>The sankey graph represents flow of data elements from the select framework through categories and pillars to other frameworks.</p>
      </b-col>
    </b-row>
    <b-row class="my-4 py-4 bg-white shadow">
      <b-col>
        <strong>Select framework:</strong><br />
        <b-select v-model="framework" :options="frameworksOptions" @change="calculateTree" placeholder="Select framework"></b-select>
      </b-col>
    </b-row>
    <b-row class="my-4 py-4 bg-white shadow" v-if="calculatingTree">
      <b-col class="text-center">
        <b-spinner label="Calculating lineage tree"></b-spinner><br />
        calculating lineage tree
      </b-col>
    </b-row>
    <b-row v-if="showGraph" class="bg-white shadow py-4">
      <b-col>
        <div>
          <sankey-simple :nodes="nodes" :links="links" :name="'Metrics and data elements graph'" :width="'100%'" :height="'100vh'"></sankey-simple>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import delay from '@/libs/delay'
import SankeySimple from '@/components/charts/v1/sankeySimple.vue'
export default {
  name: 'MetricsSankey',
  components: {
    SankeySimple
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    },
    frameworks: function () {
      return this.$store.state.reportingframeworks
    },
    frameworksOptions: function () {
      return this.frameworks.map(framework => {
        return {
          value: framework,
          text: framework.name
        }
      })
    },
    metrics: function () {
      return this.$store.state.metrics
    },
    reportingframeworks: function () {
      return this.$store.state.reportingframeworks
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      calculatingTree: false,
      framework: null,
      links: [],
      nodes: [],
      showGraph: false
    }
  },
  created: function () {
  },
  methods: {
    calculateTree: async function () {
      this.nodes = []
      this.links = []
      this.showGraph = false
      this.calculatingTree = true
      this.dataelements.forEach(dataelement => {
        dataelement.metrics.forEach(metric => {
          metric.reportingframeworks.forEach(framework => {
            if (framework.id === this.framework.id) {
              this.nodes.push({
                name: this.framework.name,
                uuid: 'framework' + this.framework.id
              })
              metric.taxonomy.forEach(taxonomy => {
                this.nodes.push({
                  name: taxonomy.name,
                  uuid: 'taxonomy' + taxonomy.id
                })
                taxonomy.parents.forEach(parent => {
                  this.nodes.push({
                    name: parent.name,
                    uuid: 'parent' + parent.id
                  })
                  this.links.push({
                    source: this.framework.name,
                    target: parent.name,
                    value: 1,
                    uuid: 'framework' + this.framework.id + 'parent' + parent.id,
                    uuidd: 'dataelement' + dataelement.id + 'framework' + this.framework.id + 'parent' + parent.id
                  })
                  this.links.push({
                    source: parent.name,
                    target: taxonomy.name,
                    value: 1,
                    uuid: 'parent' + parent.id + 'taxonomy' + taxonomy.id,
                    uuidd: 'dataelement' + dataelement.id + 'parent' + parent.id + 'taxonomy' + taxonomy.id
                  })
                })
              })
            } else {
              if (metric.reportingframeworks.some(row => row.id === this.framework.id)) {
                this.nodes.push({
                  name: framework.name,
                  uuid: 'framework' + framework.id
                })
                metric.taxonomy.forEach(taxonomy => {
                  this.links.push({
                    source: taxonomy.name,
                    target: framework.name,
                    value: 1,
                    uuid: 'taxonomy' + taxonomy.id + 'framework' + framework.id,
                    uuidd: 'dataelement' + dataelement.id + 'taxonomy' + taxonomy.id + 'framework' + framework.id
                  })
                })
              }
            }
          })
        })
      })
      this.nodes = _.uniqBy(this.nodes, 'uuid')
      this.links = _.uniqBy(this.links, 'uuidd')
      const counts = _.countBy(this.links, 'uuid')
      this.links = _.uniqBy(this.links, 'uuid')
      this.links = this.links.map(link => {
        link.value = counts[link.uuid]
        return link
      })
      this.showGraph = true
      this.calculatingTree = false
    },
    calculateTreeOld: async function () {
      this.nodes = []
      this.links = []
      this.showGraph = false
      this.calculatingTree = true
      await delay(20)
      const results = {
        name: this.framework.name,
        children: []
      }
      this.nodes.push({
        name: this.framework.name + ' (' + this.framework.id + ')'
      })
      const filteredMetricsFramework = this.metrics.filter(metric => metric.reportingframeworks.some(row => row.id === this.framework.id))
      let pillars = []
      filteredMetricsFramework.forEach(metric => {
        metric.taxonomy.forEach(taxonomy => {
          taxonomy.parents.forEach(parent => {
            pillars.push(parent)
          })
        })
      })
      pillars = _.orderBy(_.uniqBy(pillars, 'id'), ['name'], ['asc'])
      pillars.forEach(pillar => {
        this.nodes.push({
          name: pillar.name + ' (' + pillar.id + ')'
        })
        this.links.push({
          source: this.framework.name + ' (' + this.framework.id + ')',
          target: pillar.name + ' (' + pillar.id + ')',
          value: 1,
          uuid: 'framework' + this.framework.id + 'pillar' + pillar.id
        })
        results.children.push({
          name: pillar.name,
          id: pillar.id,
          children: []
        })
      })
      results.children.forEach(pillar => {
        const filteredMetricsPillar = filteredMetricsFramework.filter(metric => metric.taxonomy.some(taxonomy => taxonomy.parents.some(parent => parent.id === pillar.id)))
        let taxonomy = []
        filteredMetricsPillar.forEach(metric => {
          metric.taxonomy.forEach(taxonomyRow => {
            taxonomy.push(taxonomyRow)
          })
        })
        taxonomy = _.orderBy(_.uniqBy(taxonomy, 'id'), ['name'], ['asc'])
        pillar.children = taxonomy.map(taxonomy => {
          this.nodes.push({
            name: taxonomy.name + ' (' + taxonomy.id + ')'
          })
          this.links.push({
            source: pillar.name + ' (' + pillar.id + ')',
            target: taxonomy.name + ' (' + taxonomy.id + ')',
            value: 1,
            uuid: 'pillar' + pillar.id + 'tax' + taxonomy.id
          })
          return {
            name: taxonomy.name,
            id: taxonomy.id,
            children: []
          }
        })
        pillar.children.forEach(taxonomy => {
          const filteredMetricsTaxonomy = filteredMetricsPillar.filter(metric => metric.taxonomy.some(taxonomyRow => taxonomyRow.id === taxonomy.id))
          let metricsX = []
          filteredMetricsTaxonomy.forEach(metric => {
            metricsX.push({
              name: metric.name,
              id: metric.id
            })
          })
          metricsX = _.orderBy(_.uniqBy(metricsX, 'id'), ['name'], ['asc'])
          taxonomy.children = metricsX.map(metric => {
            return {
              name: _.truncate(metric.name, { length: 70 }),
              id: metric.id,
              children: []
            }
          })
          taxonomy.children.forEach(metric => {
            // find metric in filteredMetricsTaxonomy
            const metricX = filteredMetricsTaxonomy.find(row => row.id === metric.id)
            metric.children = metricX.dataelements.map(dataelement => {
              this.nodes.push({
                name: _.truncate(dataelement.name, { length: 70 }) + ' (' + dataelement.id + ')'
              })
              this.links.push({
                source: taxonomy.name + ' (' + taxonomy.id + ')',
                target: _.truncate(dataelement.name, { length: 70 }) + ' (' + dataelement.id + ')',
                value: 1,
                uuid: 'tax' + taxonomy.id + 'de' + dataelement.id
              })
              return {
                name: _.truncate(dataelement.name, { length: 70 }),
                id: dataelement.id,
                children: []
              }
            })
            metric.children.forEach(dataelement => {
              const dataelementX = this.dataelements.find(row => row.id === dataelement.id)
              dataelement.children = dataelementX.metrics.map(metric => {
                return {
                  name: _.truncate(metric.name, { length: 70 }),
                  id: metric.id,
                  children: []
                }
              })
              dataelement.children.forEach(metric => {
                const metricX = this.metrics.find(row => row.id === metric.id)
                metric.children = metricX.reportingframeworks.map(framework => {
                  if (framework.id !== this.framework.id) {
                    this.nodes.push({
                      name: framework.name + ' (' + framework.id + 'c)'
                    })
                    this.links.push({
                      source: dataelement.name + ' (' + dataelement.id + ')',
                      target: framework.name + ' (' + framework.id + 'c)',
                      value: 1,
                      uuid: 'de' + dataelement.id + 'fr' + framework.id
                    })
                  }
                  return {
                    name: framework.name,
                    id: framework.id
                  }
                })
              })
            })
          })
        })
      })
      this.nodes = _.uniqBy(this.nodes, 'name')
      this.links = _.uniqBy(this.links, 'uuid')
      this.calculatingTree = false
      this.showGraph = true
    }
  },
  watch: {
    metricsFiltered: function () {
      this.showGraph = false
      this.nodes = []
      this.links = []
      this.buildGraph()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
